import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import styles from "./thanks.module.scss"

const Thanks = () => {
  return (
    <Layout>
      <SEO title="Thanks" />
      <p className={styles.main}>
        Thanks <br /> for contacting
      </p>
      <Link to="/">
        <button className={styles.buttonPrimary}>&larr; Back to Home</button>
      </Link>
    </Layout>
  )
}

export default Thanks
